<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#reenterPasswordModal"
      ref="openReenterPasswordModal"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="reenterPasswordModal"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" @keyup.enter="ReenterPassword(0)">
          <div class="modal-header">
            <h6 class="modal-title">Please re-enter your password</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeReenterPasswordModal"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="user-info d-flex align-items-center">
              <div class="user-img" v-if="user">
                <img
                  v-if="user.picture == 'yes'"
                  :src="
                    Storage_URL + user.id + '/profile_picture.jpg?v=' + time
                  "
                  alt=""
                />
                <img v-else src="../../../assets/images/user-default-new.png" alt="" />
              </div>
              <span v-if="user" class="user-name">{{ user.name }}</span>
            </div>

            <p class="password-text">
              For security reasons please re-enter your password.
            </p>

            <div class="form-group">
              <input
                id="password"
                class="form-control"
                v-model.trim="$v.details.password.$model"
                spellcheck="false"
                autocomplete="off"
                :class="{
                  'is-invalid': validationStatus($v.details.password),
                }"
                trim
                :type="fieldTypes.password"
                required
                @focus="handleType"
                @blur="handleType"
                v-on:keyup="errorNull"
              />

              <span class="placeholder">Password</span>
              <i v-html="btnText" id="togglePassword" @click="showPassword"></i>
              <div
                v-if="!$v.details.password.required"
                class="invalid-tooltip"
              >
                Please enter password
              </div>
              <div
                v-if="!$v.details.password.minLength"
                class="invalid-tooltip"
              >
                Minimum 8 characters required
              </div>
              <div
                v-if="!$v.details.password.maxLength"
                class="invalid-tooltip"
              >
                Maximum limit 40 characters.
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3">
            <button data-dismiss="modal" class="btn btn-secondary">Cancel</button>
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-50"
              block
              @click="ReenterPassword(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Confirm</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-50"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "ReenterPasswordModal",
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      showLoader: 0,
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      isDisabled: true,
      mob: "",
code: "",
      details: {
        password: "",
      },
      fieldTypes: {
        password: "password",
      },
      btnText: '<i class="fa fa-eye-slash"></i>',
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  validations: {
    details: {
      password: { required, minLength: minLength(8), maxLength: maxLength(40) },
    },
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    showPassword: function () {
      if (this.fieldTypes.password === "password") {
        this.fieldTypes.password = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.fieldTypes.password = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },
    errorNull: function () {
      this.errorMessage = null;
      if (this.details.password == "" || this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    ReenterPassword: function (index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("password", this.details.password);
      formData.append("mob", this.mob);
formData.append("code", this.code);
      return axios
        .post(process.env.VUE_APP_API_URL + "user/checkPassword", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$set(this.isLoadingArray, index, false);
            this.$root.$emit("openVerifyMobileNumberModal", {
                mob: this.mob,
                code: this.code,
              });
            var elem = this.$refs.closeReenterPasswordModal;
            elem.click();
          } else {
            this.$alert(response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openReenterPasswordModal", (data) => {
      var elem = this.$refs.openReenterPasswordModal;
      elem.click();
      this.details.password = "";
      this.isDisabled = true;
      this.$v.details.password.$reset();
      this.mob = data.mob;
      this.code = data.code;
    });
  },
};
</script>
