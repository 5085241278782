<template>
  <div>
    <VerifyMobileNumberModal />
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#changeMobileNumber"
      ref="openModalChangeMobileNumber"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="changeMobileNumber"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Change Mobile Number</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeChangeMobileNumber"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p class="mobile-number-text">
              We will use this mobile number to send you important notifications
              and account recovery.
            </p>
            <form @submit.prevent @keyup.enter="changeMobileNumber(0)">
              <div class="d-md-flex align-items-center">
                <div class="phone-code">
                  <b-form-select v-model="code" :options="codeList">
                    <template #first> </template>
                  </b-form-select>
                </div>

                <div class="form-group ml-md-4 mt-3 mt-md-0 phone-number mb-0">
                  <input
                    id="mob_phone"
                    class="form-control mb-0"
                    v-model.trim="$v.mob.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.mob),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Mobile Number</span>
                  <div
                    v-if="!$v.mob.integer"
                    class="invalid-tooltip"
                  >
                    Mobile number should be number.
                  </div>
                  <div
                    v-else-if="!$v.mob.minLength"
                    class="invalid-tooltip"
                  >
                    Minimum length should be 10.
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="changeMobileNumber(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Next</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, integer } from "vuelidate/lib/validators";
import VerifyMobileNumberModal from "./VerifyMobileNumberModal.vue";
export default {
  name: "ChangeMobileNumber",
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      code: "+1",
      mob: "",
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
    };
  },
  components: {
    VerifyMobileNumberModal,
  },
  validations: {
    mob: { integer, minLength: minLength(10) },
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    changeMobileNumber: function (index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var input = document.getElementById("mob_phone").value;
      localStorage.setItem("mob", JSON.stringify(input));
      localStorage.setItem("mob_code", JSON.stringify(this.code));
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
        this.$root.$emit("openReenterPasswordModal", { mob: input, code: this.code });
        var elem = this.$refs.closeChangeMobileNumber;
        elem.click();
        this.mob = "";
      }, 500);
    },
    errorNull: function () {
      this.errorMessage = null;
      if (this.mob == "" || this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.$root.$on("openChangeMobileNumber", () => {
      var elem = this.$refs.openModalChangeMobileNumber;
      elem.click();
      this.isDisabled = true;
    });
  },
};
</script>

<style></style>
